<template>
  <nav class="flex">
    <NavTree
      class="flex-grow"
      :children="navStructure?.children"
      :depth="-1"
      expanded
      @update:expanded="onExpansionToggle"
    />
  </nav>
</template>

<script>
import { pickBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import { question as questionHelpers } from '@/helpers'
import NavTree from './NavTree'

export default {
  components: {
    NavTree,
  },
  data() {
    return {
      navStructure: null,
      expansions: {
        cluster: new Set(),
        category: new Set(),
        section: new Set(),
      },
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['clusters', 'getClustersIdentifierArray']),
  },
  methods: {
    buildNavStructure() {
      this.setCurrentRouteExpansion()

      this.navStructure = {
        expanded: true,
        children: [
          {
            entry: {
              route: {
                name: 'metadata',
              },
              text: this.$t('pages.metadata'),
            },
          },
        ].concat(
          this.getClustersIdentifierArray.map(clusterNum => {
            const { clusterIdx, categoryId, sectionId, questionId } =
              this.extractParams(this.$route.params)

            const clusterParams = { clusterNum: clusterNum }
            const cluster = this.clusters[clusterNum]

            return {
              entry: {
                route: {
                  name: 'editCluster',
                  params: clusterParams,
                },
                text: this.$t(`score.cluster-name-${clusterNum}`),
              },
              onPath: clusterNum === clusterIdx,
              expanded: this.expansions.cluster.has(clusterNum),
              modified: cluster.modified,

              children: cluster.categories.map(category => {
                const catParams = {
                  ...clusterParams,
                  categoryId: category.id.toString(),
                }
                return {
                  entry: {
                    route: {
                      name: 'editClusterCategory',
                      params: catParams,
                    },
                    text: questionHelpers.defaultingLangEntry(category.name)
                      .name,
                  },
                  onPath: catParams.categoryId === categoryId,
                  expanded: this.expansions.category.has(catParams.categoryId),
                  modified: category.modified,

                  children: category.sections.map(section => {
                    const sectionParams = {
                      ...catParams,
                      sectionId: section.id.toString(),
                    }
                    return {
                      entry: {
                        route: {
                          name: 'editClusterSection',
                          params: sectionParams,
                        },
                        text: questionHelpers.defaultingLangEntry(section.name)
                          .name,
                      },
                      onPath: sectionParams.sectionId === sectionId,
                      expanded: this.expansions.section.has(
                        sectionParams.sectionId
                      ),
                      modified: section.modified,

                      children: section.questions.map(question => {
                        const translation = questionHelpers.defaultingLangEntry(
                          question.translations
                        )
                        const questionParams = {
                          ...sectionParams,
                          questionId: question.id.toString(),
                        }
                        return {
                          entry: {
                            route: {
                              name: 'editQuestion',
                              params: questionParams,
                            },
                            text: translation.title,
                          },
                          onPath: questionParams.questionId === questionId,
                          modified: question.modified,
                        }
                      }),
                    }
                  }),
                }
              }),
            }
          })
        ),
      }
    },

    setPathHighlighting(params, highlighted) {
      const { clusterIdx, categoryId, sectionId, questionId } =
        this.extractParams(params)

      const navCluster = this.navStructure.children[parseInt(clusterIdx) + 1] // idx 0 is metadata
      navCluster.onPath = highlighted

      if (categoryId === undefined) {
        return
      }
      const navCategory = this.findNavChild(
        navCluster,
        'categoryId',
        categoryId
      )
      if (navCategory === undefined) {
        return
      }
      navCategory.onPath = highlighted

      if (sectionId === undefined) {
        return
      }
      const navSection = this.findNavChild(navCategory, 'sectionId', sectionId)
      if (navSection === undefined) {
        return
      }
      navSection.onPath = highlighted

      if (questionId === undefined) {
        return
      }
      const navQuestion = this.findNavChild(
        navSection,
        'questionId',
        questionId
      )
      if (navQuestion === undefined) {
        return
      }
      navQuestion.onPath = highlighted
    },

    setCurrentRouteExpansion() {
      const { clusterIdx, categoryId, sectionId, questionId } =
        this.extractParams(this.$route.params)

      if (categoryId !== undefined) {
        this.expansions.cluster.add(clusterIdx)
      }
      if (sectionId !== undefined) {
        this.expansions.category.add(categoryId)
      }
      if (questionId !== undefined) {
        this.expansions.section.add(sectionId)
      }
    },

    onExpansionToggle(params, isExpanded) {
      const { clusterIdx, categoryId, sectionId } = this.extractParams(params)

      let target = this.navStructure.children[parseInt(clusterIdx) + 1] // idx 0 is metadata

      const setOp = isExpanded ? 'add' : 'delete'

      if (categoryId === undefined) {
        this.expansions.cluster[setOp](clusterIdx)
        target.expanded = isExpanded
        return
      }

      target = this.findNavChild(target, 'categoryId', categoryId)

      if (sectionId === undefined) {
        this.expansions.category[setOp](categoryId)
        target.expanded = isExpanded
        return
      }

      target = this.findNavChild(target, 'sectionId', sectionId)

      this.expansions.section[setOp](sectionId)
      target.expanded = isExpanded
    },

    findNavChild(collection, name, id) {
      return collection.children.find(c => c.entry.route.params[name] === id)
    },

    extractParams(params) {
      const levels = ['clusterNum', 'categoryId', 'sectionId', 'questionId']
      const {
        clusterNum: clusterIdx,
        categoryId: categoryId,
        sectionId: sectionId,
        questionId: questionId,
      } = pickBy(params, (val, key) => levels.includes(key))
      return { clusterIdx, categoryId, sectionId, questionId }
    },
  },
  watch: {
    'clusters': {
      handler() {
        this.buildNavStructure()
      },
      deep: true,
    },
    '$i18n.locale'() {
      this.buildNavStructure()
    },
    '$route.params'(newParams, oldParams) {
      if (oldParams.clusterNum) {
        this.setPathHighlighting(oldParams, false)
      }
      if (newParams.clusterNum) {
        this.setPathHighlighting(newParams, true)
      }
      this.buildNavStructure()
    },
  },
}
</script>
