<template>
  <nav class="flex">
    <NavTree class="flex-grow" :children="navStructure?.children" :depth="-1" expanded
      @update:expanded="onExpansionToggle" />
  </nav>
</template>

<script>
import { pickBy, isNil } from 'lodash-es'
import { mapGetters } from 'vuex'
import { question as questionHelpers } from '@/helpers'
import NavTree from './NavTree'
import { EAnswerState, ENavTreeDotStatus, EQuestionType } from '@/enums'

export default {
  components: {
    NavTree,
  },
  data() {
    return {
      navStructure: null,
      expansions: {
        cluster: new Set(),
      },
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'clusters',
      'flattenedQuestions',
      'answeredQuestionsPercentage',
      'getClustersIdentifierArray',
    ]),
    ...mapGetters('auth', ['isExternalRole']),
  },
  methods: {
    buildNavStructure() {
      this.setCurrentRouteExpansion()

      const shownClusters = this.isExternalRole
        ? ['1', '2']
        : this.getClustersIdentifierArray

      this.navStructure = {
        expanded: true,
        children: [
          {
            entry: {
              route: {
                name: 'showObjectData',
              },
              text: this.$t('pages.editObjectInfo'),
            },
          },
          {
            entry: {
              route: {
                name: 'metadataResultOverview',
              },
              text: this.$t('pages.metadata'),
            },
            progress: this.answeredQuestionsPercentage,
          },
        ].concat(
          shownClusters.map(clusterNum => {
            const { clusterIdx, categoryId, sectionId, questionId } =
              this.extractParams(this.$route.params)

            const clusterParams = { clusterNum }
            const cluster = this.clusters[clusterNum]

            return {
              entry: {
                route: {
                  name: 'answerCluster',
                  params: clusterParams,
                },
                text: this.$t(`score.cluster-name-${clusterNum}`),
              },
              onPath: clusterNum === clusterIdx,
              expanded: this.expansions.cluster.has(clusterNum),
              selectable: false,
              error: false,

              children: cluster.categories.map(category => {
                const catParams = {
                  ...clusterParams,
                  categoryId: category.id.toString(),
                }
                return {
                  entry: {
                    route: {
                      name: 'answerClusterCategory',
                      params: catParams,
                    },
                    text: questionHelpers.defaultingLangEntry(category.name)
                      .name,
                  },
                  onPath: catParams.categoryId === categoryId,
                  expanded: true,
                  status: ENavTreeDotStatus.Neutral, // calculated later
                  selectable: false,

                  children: category.sections.map(section => {
                    const sectionParams = {
                      ...catParams,
                      sectionId: section.id.toString(),
                    }
                    return {
                      entry: {
                        route: {
                          name: 'answerClusterSection',
                          params: sectionParams,
                        },
                        text: questionHelpers.defaultingLangEntry(section.name)
                          .name,
                      },
                      onPath: sectionParams.sectionId === sectionId,
                      expanded: true,
                      status: ENavTreeDotStatus.Neutral, // calculated later
                      selectable: false,

                      children: section.questions.map(question => {
                        const translation = questionHelpers.defaultingLangEntry(
                          question.translations
                        )
                        const questionParams = {
                          ...sectionParams,
                          questionId: question.id.toString(),
                        }
                        return {
                          entry: {
                            route: {
                              name: 'answerQuestion',
                              params: questionParams,
                            },
                            text: translation.title,
                          },
                          onPath: questionParams.questionId === questionId,
                          modified: question.modified,
                          status: this.questionDotStatus(question),
                          taxonomy: question.taxonomyRelevant,
                          relevantForReview: question.relevantForReview
                        }
                      }),
                    }
                  }),
                }
              }),
            }
          })
        ),
      }

      this.setHierarchyDotStatus()
    },

    questionDotStatus(question) {
      if (question.parentQuestionId === null) {
        const answerState = question.answer?.state

        if (isNil(answerState)) {
          return ENavTreeDotStatus.Neutral
        }
        if (answerState === EAnswerState.Declined) {
          return ENavTreeDotStatus.Negative
        }
        if (answerState === EAnswerState.Verification) {
          return ENavTreeDotStatus.Intermediate
        }
        // If no option is selected, even if the answer object exists, it should be considered as no answer.
        if (
          (question.type === EQuestionType.MultipleChoice ||
            question.type === EQuestionType.SingleChoice) &&
          question.answer?.multipleChoiceOption.length == 0
        ) {
          return ENavTreeDotStatus.Neutral
        }
        return ENavTreeDotStatus.Positive
      }

      const parent = this.flattenedQuestions.find(
        q => q.id === question.parentQuestionId
      )
      return this.questionDotStatus(parent)
    },

    setHierarchyDotStatus() {
      // any question negative: whole parent hierarchy negative
      // else: any question intermediate: whole parent hierarchy intermediate
      // else: any question neutral: section neutral
      // else: section green

      this.navStructure.children.slice(2).forEach(cluster => {
        cluster.children.forEach(cat => {
          cat.status = ENavTreeDotStatus.Positive

          cat.children.forEach(section => {
            section.status = ENavTreeDotStatus.Positive

            let neutralFound = false
            let intermediateFound = false
            let negativeFound = false

            section.children.some(question => {
              if (question.status === ENavTreeDotStatus.Neutral) {
                neutralFound = true
              } else if (question.status === ENavTreeDotStatus.Intermediate) {
                intermediateFound = true
              } else if (question.status === ENavTreeDotStatus.Negative) {
                negativeFound = true
                return true // abort search for section
              }
            })

            if (negativeFound) {
              section.status = ENavTreeDotStatus.Negative
              cat.status = ENavTreeDotStatus.Negative
              cluster.error = true
            } else if (intermediateFound) {
              section.status = ENavTreeDotStatus.Intermediate
              cat.status = ENavTreeDotStatus.Intermediate
            } else if (neutralFound) {
              section.status = ENavTreeDotStatus.Neutral
            }
          })

          if (
            cat.status === ENavTreeDotStatus.Negative ||
            cat.status === ENavTreeDotStatus.Intermediate
          ) {
            return
          }

          // Decide between category's status being Positive or Neutral.
          if (
            cat.children.some(
              section => section.status === ENavTreeDotStatus.Neutral
            )
          ) {
            cat.status = ENavTreeDotStatus.Neutral
          }
        })
      })
    },

    setPathHighlighting(params, highlighted) {
      const { clusterIdx, categoryId, sectionId, questionId } =
        this.extractParams(params)

      // external data collector has no cluster 1 child, so offset idx differs
      const childIdx = parseInt(clusterIdx) + (this.isExternalRole ? 1 : 2)
      const navCluster = this.navStructure.children[childIdx]
      navCluster.onPath = highlighted

      if (categoryId === undefined) {
        return
      }
      const navCategory = this.findNavChild(
        navCluster,
        'categoryId',
        categoryId
      )
      if (navCategory === undefined) {
        return
      }
      navCategory.onPath = highlighted

      if (sectionId === undefined) {
        return
      }
      const navSection = this.findNavChild(navCategory, 'sectionId', sectionId)
      if (navSection === undefined) {
        return
      }
      navSection.onPath = highlighted

      if (questionId === undefined) {
        return
      }
      const navQuestion = this.findNavChild(
        navSection,
        'questionId',
        questionId
      )
      if (navQuestion === undefined) {
        return
      }
      navQuestion.onPath = highlighted
    },

    setCurrentRouteExpansion() {
      const { clusterIdx, categoryId } = this.extractParams(this.$route.params)

      if (categoryId !== undefined) {
        this.expansions.cluster.add(clusterIdx)
      }
    },

    onExpansionToggle(params, isExpanded) {
      const { clusterIdx } = this.extractParams(params)
      // external data collector has no cluster 1 child, so offset idx differs
      const childIdx = parseInt(clusterIdx) + (this.isExternalRole ? 1 : 2)
      const target = this.navStructure.children[childIdx]

      this.expansions.cluster[isExpanded ? 'add' : 'delete'](clusterIdx)
      target.expanded = isExpanded
    },

    findNavChild(collection, name, id) {
      return collection.children.find(c => c.entry.route.params[name] === id)
    },

    extractParams(params) {
      const levels = ['clusterNum', 'categoryId', 'sectionId', 'questionId']
      const {
        clusterNum: clusterIdx,
        categoryId: categoryId,
        sectionId: sectionId,
        questionId: questionId,
      } = pickBy(params, (val, key) => levels.includes(key))

      return { clusterIdx, categoryId, sectionId, questionId }
    },
  },
  watch: {
    'clusters': {
      handler() {
        this.buildNavStructure()
      },
      deep: true,
    },
    '$i18n.locale'() {
      this.buildNavStructure()
    },
    '$route.params'(newParams, oldParams) {
      if (oldParams.cluster) {
        this.setPathHighlighting(oldParams, false)
      }
      if (newParams.cluster) {
        this.setPathHighlighting(newParams, true)
      }
      this.buildNavStructure()
    },
  },
}
</script>
