<template>
  <div class="h-3 w-3 rounded-full shadow-raisedButton" :class="dotClasses" />
</template>

<script>
import { ENavTreeDotStatus } from '@/enums'
export default {
  props: {
    status: {
      type: Number,
      required: true,
      validator: val =>
        Object.values(ENavTreeDotStatus)
          .filter(key => !isNaN(Number(key)))
          .includes(val),
    },
  },
  computed: {
    dotClasses() {
      switch (this.status) {
        case ENavTreeDotStatus.Neutral:
          return 'bg-white bg-clip-content p-[3px]'
        case ENavTreeDotStatus.Positive:
          return 'bg-[#62b742]'
        case ENavTreeDotStatus.Intermediate:
          return 'bg-yellow'
        case ENavTreeDotStatus.Negative:
          return 'bg-error'
        default:
          return 'bg-lightGray'
      }
    },
  },
}
</script>
