<template>
  <circle-progress
    class="text-[13px] text-white bg-primary p-3"
    :size="70"
    :border-width="4"
    :border-bg-width="4"
    emptyfill-color="#92EC70"
    fill-color="#FFFFFF"
    :show-percent="true"
    linecap="square"
    :percent="percent"
  />
</template>

<script>
import 'vue3-circle-progress/dist/circle-progress.css'
import CircleProgress from 'vue3-circle-progress'

export default {
  components: {
    CircleProgress,
  },
  props: {
    percent: {
      type: Number,
      default: 0,
    },
  },
}
</script>
