<template>
  <div class="bg-[#DFE8E1] overflow-hidden">
    <router-link v-if="entry" class="relative flex justify-between items-center" :class="[
      entryClasses,
      progress === undefined &&
      (status !== undefined && status !== null
        ? 'py-[20px] pr-7'
        : 'py-[20px] pr-14'),
      selectable && !taxonomy
        ? 'hover:text-primary'
        : selectable && taxonomy
          ? 'hover:text-cyan'
          : 'cursor-default',
      { 'font-bold': relevantForReview }
    ]" :style="insetStyle" :to="entry.route" v-on:[preventEvent].prevent.capture>
      <span class="overflow-hidden break-words">
        {{ entry.text || '&nbsp;' }}
        <span v-if="modified" class="inline-block transform scale-125">
          *
        </span>
      </span>

      <Progress v-if="progress !== undefined" :percent="progress" />
      <Dot v-else-if="status !== undefined && status !== null" class="flex-shrink-0 ml-3" :status="status" />
      <Icon v-else-if="hasChildren" class="
          inline-block
          absolute
          top-1/2
          right-3
          transition-transform
          -translate-y-1/2
          cursor-pointer
          w-10
          p-2
        " :class="{ 'transform-gpu rotate-180': expanded }" name="chevron-down"
        @click.stop.prevent="onExpansionToggle()" />
    </router-link>

    <transition v-if="children?.length" enter-active-class="transition duration-150 ease-out"
      enter-from-class="transform-gpu scale-95 opacity-0" enter-to-class="transform-gpu scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out" leave-from-class="transform-gpu scale-100 opacity-100"
      leave-to-class="transform-gpu scale-95 opacity-0">
      <div v-show="expanded">
        <NavTree v-for="(child, idx) in children" :key="idx" v-bind="child" :depth="nextDepth"
          @update:expanded="(...args) => $emit('update:expanded', ...args)" />
      </div>
    </transition>
  </div>
</template>

<script>
import { ENavTreeDotStatus } from '@/enums'
import { pick, isEqual } from 'lodash-es'
import Icon from '@/components/Icon'
import Progress from './Progress'
import Dot from './Dot'

const entryClasses = {
  variant: {
    default: 'text-darkGray border-b-[#bfbfbf]',
    onPath: 'text-primary border-b-primary',
    matched: {
      nonTaxonomy: 'text-primary bg-[#CEE0CB] border-b-primary cursor-default',
      taxonomy: 'text-cyan bg-[#CEE0CB] border-b-primary cursor-default',
    },
    error: 'text-error border-b-[#bfbfbf]',
  },
  position: {
    cluster: 'border-b-2 text-xl font-semibold',
    nested: 'border-b',
  },
}

const validEntry = entry => entry.route && entry.text !== undefined

export default {
  components: {
    Icon,
    Progress,
    Dot,
  },
  emits: ['update:expanded'],
  props: {
    entry: {
      type: Object,
      validator: validEntry,
    },
    children: {
      type: Array,
      validator: list => list.every(c => validEntry(c.entry)),
    },
    depth: {
      type: Number,
      default: 0,
    },
    onPath: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    modified: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    taxonomy: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
    },
    status: {
      type: Number,
      required: false,
      validator: val =>
        Object.values(ENavTreeDotStatus)
          .filter(key => !isNaN(Number(key)))
          .includes(val),
    },
    relevantForReview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      routeMatched: false,
    }
  },
  computed: {
    preventEvent() {
      return this.selectable ? null : 'click'
    },
    entryClasses() {
      const variant = this.error
        ? 'error'
        : this.routeMatched
          ? 'matched'
          : this.onPath
            ? 'onPath'
            : 'default'
      const taxonomy = this.taxonomy ? 'taxonomy' : 'nonTaxonomy'
      const nesting = this.depth === 0 ? 'cluster' : 'nested'
      return `${this.routeMatched
        ? entryClasses.variant[variant][taxonomy]
        : entryClasses.variant[variant]
        } 
        ${entryClasses.position[nesting]}`
    },
    hasChildren() {
      return !!this.children?.length
    },
  },
  methods: {
    isRouteMatched() {
      if (!this.entry) {
        return false
      }

      const route = this.entry.route

      return (
        route.name === this.$route.name &&
        (!route.params ||
          isEqual(
            pick(this.$route.params, Object.keys(route.params)),
            route.params
          ))
      )
    },
    onExpansionToggle() {
      if (this.hasChildren) {
        this.$emit('update:expanded', this.entry.route.params, !this.expanded)
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.routeMatched = this.isRouteMatched()
      },
      immediate: true,
    },
  },
  created() {
    const padding = this.depth > 1 ? 25 + (this.depth - 1) * 20 : 25
    this.insetStyle = { 'padding-left': `${padding}px` }
    this.nextDepth = this.depth + 1
  },
}
</script>
