<template>
  <nav class="flex">
    <NavTree
      class="flex-grow"
      :children="navStructure?.children"
      :depth="-1"
      expanded
      @update:expanded="onExpansionToggle"
    />
  </nav>
</template>

<script>
import { pickBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import { user, question as questionHelpers } from '@/helpers'
import NavTree from './NavTree'
import {
  EUserRole,
  EAnswerState,
  ENavTreeDotStatus,
  EAssetState,
} from '@/enums'

export default {
  components: {
    NavTree,
  },
  data() {
    return {
      navStructure: null,
      expansions: {
        cluster: new Set(),
      },
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'clusters',
      'assetState',
      'reviewedQuestionsPercentage',
      'evaluatedQuestionsPercentage',
      'getClustersIdentifierArray',
    ]),
    isReviewer() {
      return user.hasPermission([
        EUserRole.InternalTester,
        EUserRole.ExternalAccreditedTester,
      ])
    },
  },
  methods: {
    buildNavStructure() {
      this.setCurrentRouteExpansion()

      this.navStructure = {
        expanded: true,
        children: [
          {
            entry: {
              route: {
                name: 'checkShowObjectData',
              },
              text: this.$t('pages.editObjectInfo'),
            },
          },
          {
            entry: {
              route: {
                name: 'metadataCheckOverview',
              },
              text: this.$t('pages.metadata'),
            },
            progress:
              this.assetState === EAssetState.Review
                ? this.reviewedQuestionsPercentage
                : this.evaluatedQuestionsPercentage,
          },
        ].concat(
          this.getClustersIdentifierArray.map(clusterNum => {
            const { clusterIdx, categoryId, sectionId, questionId } =
              this.extractParams(this.$route.params)

            const clusterParams = { clusterNum: clusterNum }
            const cluster = this.clusters[clusterNum]

            return {
              entry: {
                route: {
                  name: 'checkCluster',
                  params: clusterParams,
                },
                text: this.$t(`score.cluster-name-${clusterNum}`),
              },
              onPath: clusterNum === clusterIdx,
              expanded: this.expansions.cluster.has(clusterNum),
              selectable: false,
              error: false,

              children: cluster.categories.map(category => {
                const catParams = {
                  ...clusterParams,
                  categoryId: category.id.toString(),
                }

                const categoryResult = {
                  entry: {
                    route: {
                      name: 'checkClusterCategory',
                      params: catParams,
                    },
                    text: questionHelpers.defaultingLangEntry(category.name)
                      .name,
                  },
                  onPath: catParams.categoryId === categoryId,
                  expanded: true,
                  status: ENavTreeDotStatus.Positive,
                  selectable: false,
                }

                categoryResult.children = category.sections.map(section => {
                  const sectionParams = {
                    ...catParams,
                    sectionId: section.id.toString(),
                  }

                  const sectionResult = {
                    entry: {
                      route: {
                        name: 'checkClusterSection',
                        params: sectionParams,
                      },
                      text: questionHelpers.defaultingLangEntry(section.name)
                        .name,
                    },
                    onPath: sectionParams.sectionId === sectionId,
                    expanded: true,
                    status: ENavTreeDotStatus.Positive,
                    selectable: false,
                  }

                  sectionResult.children = section.questions.map(question => {
                    const translation = questionHelpers.defaultingLangEntry(
                      question.translations
                    )
                    const questionParams = {
                      ...sectionParams,
                      questionId: question.id.toString(),
                    }

                    const questionResult = {
                      entry: {
                        route: {
                          name: 'checkQuestion',
                          params: questionParams,
                        },
                        text: translation.title,
                      },
                      modified: question.modified,
                      onPath: questionParams.questionId === questionId,
                      status: this.questionDotStatus(question.answer?.state),
                    }

                    return questionResult
                  })

                  return sectionResult
                })

                return categoryResult
              }),
            }
          })
        ),
      }

      this.setHierarchyDotStatus()
    },

    questionDotStatus(questionState) {
      if (this.isReviewer) {
        switch (questionState) {
          case EAnswerState.ReviewAccepted:
          case EAnswerState.EvaluationAccepted:
            return ENavTreeDotStatus.Positive
          case EAnswerState.Declined:
            return ENavTreeDotStatus.Negative
          case EAnswerState.Verification:
            return ENavTreeDotStatus.Intermediate
          case EAnswerState.Answered:
          default:
            return ENavTreeDotStatus.Neutral
        }
      }

      switch (questionState) {
        case EAnswerState.EvaluationAccepted:
          return ENavTreeDotStatus.Positive
        case EAnswerState.Declined:
          return ENavTreeDotStatus.Negative
        case EAnswerState.ReviewAccepted:
          return ENavTreeDotStatus.Intermediate
        case EAnswerState.Answered:
        default:
          return ENavTreeDotStatus.Neutral
      }
    },

    setHierarchyDotStatus() {
      // any question intermediate: whole parent hierarchy intermediate
      // else: any question negative: whole parent hierarchy negative
      // else: any question neutral: section neutral
      // else: section green

      this.navStructure.children.slice(2).forEach(cluster => {
        cluster.children.forEach(cat => {
          cat.status = ENavTreeDotStatus.Positive

          cat.children.forEach(section => {
            section.status = ENavTreeDotStatus.Positive

            let neutralFound = false
            let intermediateFound = false
            let negativeFound = false

            section.children.some(question => {
              if (question.status === ENavTreeDotStatus.Negative) {
                negativeFound = true
              } else if (question.status === ENavTreeDotStatus.Neutral) {
                neutralFound = true
              } else if (question.status === ENavTreeDotStatus.Intermediate) {
                intermediateFound = true
                return true // abort search for section
              }
            })

            if (intermediateFound) {
              section.status = ENavTreeDotStatus.Intermediate
              cat.status = ENavTreeDotStatus.Intermediate
            } else if (negativeFound) {
              section.status = ENavTreeDotStatus.Negative
              cat.status = ENavTreeDotStatus.Negative
              cluster.error = true
            } else if (neutralFound) {
              section.status = ENavTreeDotStatus.Neutral
            }
          })

          if (
            cat.status === ENavTreeDotStatus.Intermediate ||
            cat.status === ENavTreeDotStatus.Negative
          ) {
            return
          }

          if (
            cat.children.some(
              section => section.status === ENavTreeDotStatus.Neutral
            )
          ) {
            cat.status = ENavTreeDotStatus.Neutral
          }
        })
      })
    },

    setPathHighlighting(params, highlighted) {
      const { clusterIdx, categoryId, sectionId, questionId } =
        this.extractParams(params)

      const navCluster = this.navStructure.children[parseInt(clusterIdx) + 2]
      navCluster.onPath = highlighted

      if (categoryId === undefined) {
        return
      }
      const navCategory = this.findNavChild(
        navCluster,
        'categoryId',
        categoryId
      )
      if (navCategory === undefined) {
        return
      }
      navCategory.onPath = highlighted

      if (sectionId === undefined) {
        return
      }
      const navSection = this.findNavChild(navCategory, 'sectionId', sectionId)
      if (navSection === undefined) {
        return
      }
      navSection.onPath = highlighted

      if (questionId === undefined) {
        return
      }
      const navQuestion = this.findNavChild(
        navSection,
        'questionId',
        questionId
      )
      if (navQuestion === undefined) {
        return
      }
      navQuestion.onPath = highlighted
    },

    setCurrentRouteExpansion() {
      const { clusterIdx, categoryId } = this.extractParams(this.$route.params)

      if (categoryId !== undefined) {
        this.expansions.cluster.add(clusterIdx)
      }
    },

    onExpansionToggle(params, isExpanded) {
      const { clusterIdx } = this.extractParams(params)
      let target = this.navStructure.children[parseInt(clusterIdx) + 2]

      this.expansions.cluster[isExpanded ? 'add' : 'delete'](clusterIdx)
      target.expanded = isExpanded
    },

    findNavChild(collection, name, id) {
      return collection.children.find(c => c.entry.route.params[name] === id)
    },

    extractParams(params) {
      const levels = ['clusterNum', 'categoryId', 'sectionId', 'questionId']
      const {
        clusterNum: clusterIdx,
        categoryId: categoryId,
        sectionId: sectionId,
        questionId: questionId,
      } = pickBy(params, (val, key) => levels.includes(key))

      return { clusterIdx, categoryId, sectionId, questionId }
    },
  },
  watch: {
    'clusters': {
      handler() {
        this.buildNavStructure()
      },
      deep: true,
    },
    '$i18n.locale'() {
      this.buildNavStructure()
    },
    '$route.params'(newParams, oldParams) {
      if (oldParams.cluster) {
        this.setPathHighlighting(oldParams, false)
      }
      if (newParams.cluster) {
        this.setPathHighlighting(newParams, true)
      }
      this.buildNavStructure()
    },
  },
}
</script>
