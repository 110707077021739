<template>
  <div class="flex flex-col bg-lightestGray">
    <ClipLoader v-if="requestsPending" class="flex flex-center h-60" color="green" size="4rem" />
    <router-view
      v-else-if="score || organisationIsTestAccount"
      :questionnaireId="questionnaireId"
      :clustersTable="clustersTable"
      :taxonomyScore="taxonomyScore"
      :financeScore="financeScore"
      @setToFullScreen="setToFullScreen=true"
      :class="{'h-[50vh]':setToFullScreen}"
    />
  </div>
</template>

<script>
import { sumBy, sortBy, partialRight } from 'lodash-es'
import { reactive } from 'vue'
import { mapGetters, mapActions, useStore } from 'vuex'
import { general } from '@/helpers'
import { useRequest } from '@/composition'
import { questionnaires as questionnairesApi } from '@/services/api'
import ClipLoader from 'vue-spinner/src/ClipLoader'
import defaultClustersTable from '@/assets/files/DefaultClustersTable.json'

export default {
  components: {
    ClipLoader,
  },
  props: {
    questionnaireId: {
      type: String,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    isBuyerAsset: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      score: null,
      clustersTable: null,
      setToFullScreen: false,
      financeScore: 0,
    }
  },

  setup(props) {
    const store = useStore()
    const questionnaireRequest = useRequest(() => {
      const payload = {
        assetId: props.assetId,
        questionnaireId: props.questionnaireId,
        isBuyerAsset: props.isBuyerAsset,
      }
      return store.dispatch('questionnaire/fetchForAsset', payload)
    })
    const scoreRequest = useRequest(() =>
      questionnairesApi.getAssetScore(
        props.assetId,
        props.questionnaireId,
        props.isBuyerAsset
      )
    )
    const organisationIsTestAccount =
      store.getters['auth/organisationIsTestAccount']

    return reactive({
      questionnaireRequest,
      scoreRequest,
      organisationIsTestAccount,
    })
  },
  computed: {
    ...mapGetters('questionnaire', ['clusters']),
    requestsPending() {
      return this.questionnaireRequest.pending || this.scoreRequest.pending
    },
    taxonomyScore() {
      return this.organisationIsTestAccount
        ? 50 /* Dummy value which will be hidden. */
        : this.score?.taxonomyScore
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    setClustersTable() {
      const sort = partialRight(sortBy, 'order')
      const table = this.clusters.map((clst, clusterIdx) => {
        const cluster = {
          ...clst,
        }
        cluster.categories = cluster.categories.map((cat, catIdx) => {
          const catScore = {
            ...this.score?.clusters[clusterIdx].categories[catIdx],
            sections: sort(
              this.score?.clusters[clusterIdx].categories[catIdx].sections
            ),
          }
          const category = {
            ...cat,
            identifier: `CLU ${general.numeral(clusterIdx + 1)} ${catIdx + 1}`,
            weight: sumBy(cat.sections, 'weight'),
            sections: cat.sections.map(section => ({
              ...section,
              weightOverall: (cluster.weight / 100) * section.weight,
            })),
          }
          category.weightOverall = sumBy(category.sections, 'weightOverall')

          category.sections.forEach((section, sectIdx) => {
            section.maxWeightedScoreOverall =
              catScore?.sections[sectIdx]?.maxWeightedScore
            section.weightedAchievedScoreOverall =
              catScore?.sections[sectIdx]?.reachedWeightedScore
            section.unweightedAchievedScoreOverall =
              catScore?.sections[sectIdx]?.reachedUnweightedScore
            section.taxonomyMaxScore =
              catScore?.sections[sectIdx]?.taxonomyMaxScore
            section.reachedTaxonomyScore =
              catScore?.sections[sectIdx]?.reachedTaxonomyScore
          })

          category.maxWeightedScoreOverall = catScore?.maxWeightedScore
          category.weightedAchievedScoreOverall = catScore?.reachedWeightedScore
          category.unweightedAchievedScoreOverall =
            catScore?.reachedUnweightedScore
          category.maxWeightedFinanceScore = catScore?.maxWeightedFinanceScore
          category.reachedTaxonomyScore = catScore?.reachedTaxonomyScore
          return category
        })

        cluster.weightedAchievedScoreOverall =
          this.score.clusters[clusterIdx]?.reachedWeightedScore

        cluster.maxWeightedScore = sumBy(
          cluster.categories,
          'maxWeightedScoreOverall'
        )
        cluster.maxWeightedFinanceScore = sumBy(
          cluster.categories,
          'maxWeightedFinanceScore'
        )

        return cluster
      })

      table.forEach(cluster => {
        cluster.totals = cluster.categories.reduce(
          (result, cat) => {
            result.weight += cat.weight
            result.weightOverall += cat.weightOverall
            result.maxScore = cluster.maxScore
            result.maxWeightedScoreOverall += cat.maxWeightedScoreOverall
            result.weightedAchievedScoreOverall +=
              cat.weightedAchievedScoreOverall
            result.unweightedAchievedScoreOverall +=
              cat.unweightedAchievedScoreOverall
            result.maxWeightedFinanceScore += cat.maxWeightedFinanceScore
            result.reachedTaxonomyScoreOverall += cat.reachedTaxonomyScore
            return result
          },
          {
            weight: 0,
            weightOverall: 0,
            maxScore: 0,
            maxWeightedScoreOverall: 0,
            weightedAchievedScoreOverall: 0,
            unweightedAchievedScoreOverall: 0,
            maxWeightedFinanceScore: 0,
            reachedTaxonomyScoreOverall: 0,
          }
        )
      })
      return table
    },

    async fetchQuestionnaire() {
      if (
        this.$route.name === 'questionnaireResultOverview' ||
        this.$route.name === 'questionnaireResultDetails'
      ) {
        try {
          await this.questionnaireRequest.request()
        } catch (error) {
          this.showMessage({
            type: 'error',
            translationKey: 'questionnaire-fetch-error',
          })
          throw error
        }
      }
    },

    async fetchScore() {
      try {
        return await this.scoreRequest.request()
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-score-fetch-error',
        })
        throw error
      }
    },

    async fetch() {
      if (this.organisationIsTestAccount) {
        this.clustersTable = defaultClustersTable
        return
      }
      const [, score] = await Promise.all([
        this.fetchQuestionnaire(),
        this.fetchScore(),
      ])
      this.score = score
      this.clustersTable = this.setClustersTable()
      this.financeScore = sumBy(
        this.score.clusters,
        'reachedWeightedFinanceScore'
      ).toFixed(2)
    },
  },
  watch: {
    questionnaire: {
      handler() {
        this.fetch()
      },
    },
    questionnaireId() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
}
</script>
