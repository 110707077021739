<template>
  <td>
    <div class="flex justify-center items-center">
      <!-- Magic Number: ml -45px -->
      <input
        class="
          bg-transparent
          text-right
          rounded
          w-[62px]
          ml-[-45px]
          focus:outline-none
        "
        :class="[
          {
            'ring-1 ring-primary': editMode,
            'ring-1 ring-error': invalid,
            'text-error': percentageError,
            'font-bold': isResult,
          },
        ]"
        maxlength="5"
        :disabled="!editMode"
        v-model="stringModel"
        ref="input"
        @keydown.enter="finishEdit"
        @blur="finishEdit"
      />

      <div
        v-if="isPercentage"
        class="text-center overflow-visible"
        :class="percentageError && 'text-error'"
      >
        %
      </div>
      <Icon
        v-if="editable"
        name="edit"
        class="w-4 -ml-4 transform translate-x-6 cursor-pointer"
        @click="startEdit"
      />
    </div>
  </td>
</template>

<script>
import { useFloatInput } from '@/composition'
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    isPercentage: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    isResult: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    return useFloatInput(props, emit, 2)
  },
  data() {
    return {
      editMode: false,
    }
  },
  computed: {
    percentageError() {
      return this.isPercentage && this.modelValue > 100
    },
  },
  methods: {
    startEdit() {
      this.editMode = true
      this.$nextTick(() => this.$refs.input.focus())
    },
    finishEdit() {
      this.editMode = false
      this.emitFloat()
    },
  },
}
</script>

<style lang="css">
/* The disabled input has opacity on it in safari so we need the hack here */
.safari-lightBlack input:disabled:not(.text-error) {
  color: theme('colors.lightBlack');
  -webkit-text-fill-color: theme('colors.lightBlack');
}
</style>
