<template>
  <div class="bg-white rounded text-lightBlack">
    <h2
      class="text-lg font-bold px-11 py-9"
    >{{ $t('score.metadata-allClusters-header',{start:toRoman(1),end:toRoman(numberofCluster)}) }}</h2>

    <table class="table-fixed w-full">
      <thead class="text-sm bg-oliveGreen">
        <tr>
          <th
            class="text-left text-ellipsis py-3 pl-11"
          >{{ $t('score.metadata-allClusters-clusterName') }}</th>
          <th
            class="text-center text-ellipsis py-3"
            v-for="(cluster,i) in clusters"
            :key="i"
          >{{ `${$t('score.cluster')} ${toRoman(i+1)}` }}</th>
        </tr>
      </thead>

      <tbody>
        <tr class="border-b border-lighterGray h-16 safari-lightBlack">
          <td
            class="text-left font-semibold text-ellipsis pl-11"
          >{{ $t('score.metadata-allClusters-maxScore') }}</td>
          <ScoreTableCell colspan="3" :modelValue="totalScore" />
        </tr>
        <tr
          class="border-b border-lighterGray h-16"
          :class="totalWeight !== 100 ? 'text-error' : 'safari-lightBlack'"
        >
          <td
            class="text-left font-semibold text-ellipsis pl-11"
          >{{ $t('score.metadata-allClusters-weight') }}</td>
          <ScoreTableCell
            v-for="(cluster, idx) in clusters"
            :key="idx"
            class="last:pr-11"
            :modelValue="cluster.weight"
            isPercentage
            :editable="editable"
            @update:modelValue="val => $emit('update:weight', val, idx)"
          />
        </tr>
        <tr class="h-16 safari-lightBlack border-b border-lighterGray">
          <td
            class="text-left text-ellipsis pl-11"
            :class="results.length === 0 ? 'font-bold ' : 'font-semibold'"
          >{{ $t('score.metadata-allClusters-maxWeightedScore') }}</td>
          <ScoreTableCell
            v-for="(cluster, idx) in clusters"
            :key="idx"
            class="last:pr-11"
            :modelValue="cluster.maxWeightedScore"
            :isResult="results.length === 0"
          />
        </tr>
        <tr
          class="border-b border-lighterGray h-16"
          :class="
            totalFinanceWeight !== 100 ? 'text-error' : 'safari-lightBlack'
          "
        >
          <td
            class="text-left font-semibold text-ellipsis pl-11"
          >{{ $t('score.metadata-allClusters-finance-weight') }}</td>
          <ScoreTableCell
            v-for="(cluster, idx) in clusters"
            :key="idx"
            class="last:pr-11"
            :modelValue="cluster.financeWeight"
            isPercentage
            :editable="editable"
            @update:modelValue="val => $emit('update:financeWeight', val, idx)"
          />
        </tr>
        <tr class="h-16 safari-lightBlack" :class="results.length && 'border-b border-lighterGray'">
          <td
            class="text-left text-ellipsis pl-11"
            :class="results.length === 0 ? 'font-bold ' : 'font-semibold'"
          >{{ $t('score.metadata-allClusters-maxFinanceWeightedScore') }}</td>
          <ScoreTableCell
            v-for="(cluster, idx) in clusters"
            :key="idx"
            class="last:pr-11"
            :modelValue="cluster.maxWeightedFinanceScore"
            :isResult="results.length === 0"
          />
        </tr>
        <tr class="h-16 border-b border-lighterGray" v-if="results.length > 0">
          <td
            class="text-left font-semibold text-ellipsis pl-11"
          >{{ $t('score.metadata-allClusters-result') }}</td>
          <ScoreTableCell
            v-for="(result, idx) in results"
            :key="idx"
            class="last:pr-11"
            :modelValue="result"
          />
        </tr>
        <tr v-if="results.length" class="h-16">
          <td class="text-left font-bold text-ellipsis pl-11">{{ $t('score.metadata-ecore-score') }}</td>
          <ScoreTableCell colspan="3" :modelValue="overallEcoreScore" isResult isPercentage />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { sumBy, round } from 'lodash-es'
import ScoreTableCell from './ScoreTableCell'
import { numeral } from '@/helpers/general'
import { mapGetters } from 'vuex'
export default {
  components: {
    ScoreTableCell,
  },
  emits: ['update:weight', 'update:financeWeight'],
  props: {
    results: {
      type: Array,
      default: () => [],
      required: false,
    },
    clusters: {
      type: Array,
      default: () => [],
      validator: list =>
        list.every(
          cluster =>
            cluster.weight !== undefined &&
            cluster.maxWeightedScore !== undefined &&
            cluster.financeWeight !== undefined &&
            cluster.maxWeightedFinanceScore !== undefined
        ),
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('questionnaire', ['getClustersIdentifierArray']),
    totalWeight() {
      return round(sumBy(this.clusters, 'weight'), 2)
    },
    totalFinanceWeight() {
      return round(sumBy(this.clusters, 'financeWeight'), 2)
    },
    totalScore() {
      return round(sumBy(this.clusters, 'maxWeightedScore'), 2)
    },
    overallEcoreScore() {
      return round(sumBy(this.clusters, 'weightedAchievedScoreOverall'), 2)
    },
    numberofCluster() {
      return this.getClustersIdentifierArray.length
    },
  },
  methods: {
    toRoman(num) {
      return numeral(num)
    },
  },
}
</script>
