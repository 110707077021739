<template>
  <div class="bg-white rounded text-lightBlack">
    <h2 class="flex justify-between text-lg font-bold px-11 py-9">
      <span>{{ defaultingLangEntry(name) }}</span>
      <span
        class="whitespace-nowrap ml-6"
        :class="weight > 100 && 'text-error'"
      >{{ $t('score.metadata-cluster-ownWeight', { weight }) }}</span>
    </h2>

    <table class="table-fixed text-sm w-full">
      <thead class="bg-oliveGreen">
        <tr>
          <th class="text-left text-ellipsis py-3 pl-11">{{ $t('score.category') }}</th>
          <th class="text-left text-ellipsis py-3">{{ $t('score.metadata-cluster-scope') }}</th>
          <th
            class="text-center text-ellipsis py-3"
          >{{ $t('score.metadata-cluster-maxScore', { numeral }) }}</th>
          <template v-if="!isUserRestricted">
            <th
              class="text-center text-ellipsis py-3"
            >{{ $t('score.metadata-cluster-weight', { numeral }) }}</th>
            <th
              class="text-center text-ellipsis py-3"
            >{{ $t('score.metadata-cluster-weightOverall') }}</th>
            <th
              class="text-center text-ellipsis py-3"
              :class="variant !== 'answer' ? 'pr-11' : ''"
            >{{ $t('score.metadata-cluster-maxWeightedScoreOverall') }}</th>
            <th
              class="text-center text-ellipsis py-3"
            >{{ $t('score.metadata-cluster-finance-weight', { numeral }) }}</th>
            <th
              class="text-center text-ellipsis py-3"
            >{{ $t('score.metadata-cluster-finance-weightOverall') }}</th>
            <th
              class="text-center text-ellipsis py-3"
              :class="variant !== 'answer' ? 'pr-11' : ''"
            >{{ $t('score.metadata-cluster-maxFinanceWeightedScoreOverall') }}</th>
          </template>
          <th
            v-if="variant === 'answer'"
            class="text-center text-ellipsis py-3"
          >{{ $t('score.metadata-cluster-reachedUnweightedScore') }}</th>

          <template v-if="!isUserRestricted">
            <th
              v-if="variant === 'answer'"
              class="text-center text-ellipsis py-3 pr-11"
            >{{ $t('score.metadata-cluster-reachedScore') }}</th>
          </template>
        </tr>
      </thead>

      <tbody>
        <template v-for="(cat, catIdx) in categories" :key="catIdx">
          <tr class="border-b border-lighterGray h-16 safari-lightBlack">
            <td class="font-semibold text-ellipsis pl-11">{{ cat.identifier }}</td>
            <td class="font-semibold text-ellipsis">{{ defaultingLangEntry(cat.name) }}</td>
            <ScoreTableCell :modelValue="cat.maxScore" />
            <template v-if="!isUserRestricted">
              <ScoreTableCell :modelValue="cat.weight" isPercentage />
              <ScoreTableCell :modelValue="cat.weightOverall" isPercentage />
              <ScoreTableCell
                :class="variant !== 'answer' ? 'pr-11' : ''"
                :modelValue="cat.maxWeightedScoreOverall"
              />
              <ScoreTableCell :modelValue="cat.financeWeight" isPercentage />
              <ScoreTableCell :modelValue="cat.financeWeightOverall" isPercentage />
              <ScoreTableCell
                :class="variant !== 'answer' ? 'pr-11' : ''"
                :modelValue="cat.maxFinanceWeightedScoreOverall"
              />
            </template>
            <ScoreTableCell
              v-if="variant === 'answer' && !isTaxonomyCluster"
              :modelValue="cat.unweightedAchievedScoreOverall"
            />
            <ScoreTableCell
              v-if="variant === 'answer' && isTaxonomyCluster"
              :modelValue="cat.reachedTaxonomyScore"
            />
            <template v-if="!isUserRestricted">
              <ScoreTableCell
                v-if="variant === 'answer'"
                class="pr-11"
                :modelValue="cat.weightedAchievedScoreOverall"
              />
            </template>
          </tr>
          <tr
            v-for="(section, sectIdx) in cat.sections"
            :key="sectIdx"
            class="border-b border-lighterGray h-16 safari-lightBlack"
          >
            <td />
            <td class="font-medium text-ellipsis">{{ defaultingLangEntry(section.name) }}</td>
            <ScoreTableCell :modelValue="section.taxonomyMaxScore" v-if="showTaxonomyScore" />
            <ScoreTableCell :modelValue="section.maxScore" v-else />

            <template v-if="!isUserRestricted">
              <ScoreTableCell
                :modelValue="section.weight"
                isPercentage
                :editable="editable"
                @update:modelValue="
                  val => $emit('update:weight', val, catIdx, sectIdx)
                "
              />
              <ScoreTableCell :modelValue="section.weightOverall" isPercentage />

              <ScoreTableCell
                :class="variant !== 'answer' ? 'pr-11' : ''"
                :modelValue="section.maxWeightedScoreOverall"
              />
              <ScoreTableCell
                :modelValue="section.financeWeight"
                isPercentage
                :editable="editable"
                @update:modelValue="
                  val => $emit('update:financeWeight', val, catIdx, sectIdx)
                "
              />
              <ScoreTableCell :modelValue="section.financeWeightOverall" isPercentage />
              <ScoreTableCell
                :class="variant !== 'answer' ? 'pr-11' : ''"
                :modelValue="section.maxFinanceWeightedScoreOverall"
              />
            </template>
            <template v-if="variant === 'answer'">
              <ScoreTableCell :modelValue="section.reachedTaxonomyScore" v-if="showTaxonomyScore" />

              <ScoreTableCell :modelValue="section.unweightedAchievedScoreOverall" v-else />
            </template>

            <template v-if="!isUserRestricted">
              <ScoreTableCell
                v-if="variant === 'answer'"
                class="pr-11"
                :modelValue="section.weightedAchievedScoreOverall"
              />
            </template>
          </tr>
        </template>
      </tbody>

      <tfoot class="bg-oliveGreen font-bold">
        <tr>
          <td />
          <td class="text-left text-ellipsis py-5">{{ $t('score.metadata-cluster-totals') }}</td>
          <td class="text-center py-5">{{ roundedTotals.maxScore }}</td>
          <template v-if="!isUserRestricted">
            <td
              class="text-center py-5"
              :class="roundedTotals.weight !== 100 && 'text-error'"
            >{{ `${roundedTotals.weight}%` }}</td>
            <td
              class="text-center py-5"
              :class="roundedTotals.weightOverall > 100 && 'text-error'"
            >{{ `${roundedTotals.weightOverall}%` }}</td>
            <td
              class="text-center py-5"
              :class="variant !== 'answer' ? 'pr-11' : ''"
            >{{ roundedTotals.maxWeightedScoreOverall }}</td>

            <td
              class="text-center py-5"
              :class="roundedTotals.financeWeight !== 100 && 'text-error'"
            >{{ `${roundedTotals.financeWeight}%` }}</td>
            <td
              class="text-center py-5"
              :class="roundedTotals.financeWeightOverall > 100 && 'text-error'"
            >{{ `${roundedTotals.financeWeightOverall}%` }}</td>
            <td
              class="text-center py-5"
              :class="variant !== 'answer' ? 'pr-11' : ''"
            >{{ roundedTotals.maxFinanceWeightedScoreOverall }}</td>
          </template>
          <td
            v-if="variant === 'answer'&& !isTaxonomyCluster"
            class="text-center py-5"
          >{{ roundedTotals.unweightedAchievedScoreOverall }}</td>
          <td
            v-if="variant === 'answer' && isTaxonomyCluster"
            class="text-center py-5"
          >{{ roundedTotals.reachedTaxonomyScoreOverall }}</td>
          <template v-if="!isUserRestricted">
            <td
              v-if="variant === 'answer' "
              class="text-center py-5 pr-11"
            >{{ roundedTotals.weightedAchievedScoreOverall }}</td>
          </template>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { round, mapValues, partialRight } from 'lodash-es'
import { general, question as questionHelpers, user } from '@/helpers'
import ScoreTableCell from './ScoreTableCell'

import { EUserRole } from '@/enums'

const hasAllRowValues = entry =>
  entry.weight !== undefined &&
  entry.weightOverall !== undefined &&
  entry.maxScore !== undefined &&
  entry.maxWeightedScoreOverall !== undefined &&
  entry.maxFinanceWeightedScoreOverall !== undefined

const isValidTableRow = entry => entry.name && hasAllRowValues(entry)

export default {
  components: {
    ScoreTableCell,
  },
  emits: ['update:weight', 'update:financeWeight'],
  props: {
    variant: {
      type: String,
      default: 'template',
      validator: val => ['template', 'answer'].includes(val),
    },
    number: {
      type: Number,
      default: 1,
    },
    name: {
      type: Array,
      default: () => [
        {
          language: 'de',
          name: 'Cluster',
        },
      ],
    },
    weight: {
      type: Number,
      default: 0,
    },
    categories: {
      type: Array,
      default: () => [],
      validator: cats =>
        cats.every(
          cat =>
            cat.identifier &&
            isValidTableRow(cat) &&
            cat.sections.every(isValidTableRow)
        ),
    },
    totals: {
      type: Object,
      default: () => ({
        weight: 0,
        weightOverall: 0,
        maxScore: 0,
        maxWeightedScoreOverall: 0,
        financeWeight: 0,
        financeWeightOverall: 0,
        maxFinanceWeightedScoreOverall: 0,
        reachedTaxonomyScoreOverall: 0,
      }),
      validator: hasAllRowValues,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    isTaxonomyCluster: {
      type: Boolean,
    },
  },
  setup() {
    const isUserRestricted = user.isRestricted([
      EUserRole.AccountAdministrator,
      EUserRole.ObjectAdministrator,
      EUserRole.ObjectDataCollector,
      EUserRole.InternalTester,
      EUserRole.ExternalAccreditedTester,
      EUserRole.Evaluator,
      EUserRole.ProspectiveBuyer,
    ])
    return {
      isUserRestricted,
    }
  },
  computed: {
    numeral() {
      return general.numeral(this.number)
    },
    roundedTotals() {
      const numberToFixed = number =>
        this.isFloat(number) ? number.toFixed(2) : number
      return mapValues(this.totals, numberToFixed)
    },
    showTaxonomyScore() {
      return this.isTaxonomyCluster && this.isUserRestricted
    },
  },
  methods: {
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0
    },
    defaultingLangEntry(langs) {
      return questionHelpers.defaultingLangEntry(langs).name
    },
  },
}
</script>
