<template>
  <nav class="flex">
    <NavTree
      class="flex-grow"
      :children="navStructure?.children"
      :depth="-1"
      expanded
    />
  </nav>
</template>

<script>
import { last, isNil } from 'lodash-es'
import NavTree from './NavTree'
import { EAssetState } from '@/enums'

export default {
  components: {
    NavTree,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    questionnaires: {
      type: Array,
      default: () => [],
      validator: list => list.every(q => !isNil(q.id) && q.title),
    },
  },
  computed: {
    isOnResultDetailRoute() {
      return last(this.$route.matched).name === 'questionnaireResultDetails'
    },
    navStructure() {
      return {
        expanded: true,
        children: [
          {
            entry: {
              route: {
                name: 'editObjectData',
              },
              text: this.$t('pages.editObjectInfo'),
            },
          },
        ].concat(
          this.questionnaires.map(({ id, title, assetQuestionnaireState }) => ({
            entry: {
              route: {
                name: this.isOnResultDetailRoute
                  ? 'questionnaireResultDetails'
                  : 'questionnaireResultOverview',
                params: {
                  assetId: this.object.id.toString(),
                  questionnaireId: id.toString(),
                },
              },
              text: `${title}${(assetQuestionnaireState === EAssetState.Expired ? ` (${this.$t('assetManagement.expired')})` : '')}`,
            },
          }))
        ),
      }
    },
  },
}
</script>
